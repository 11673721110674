<template>
  <div id="app">
    <div :class="styles['common-container']">
      <!-- <common-main v-if="which === 'dronos'" /> -->
      <!-- <sidebar /> -->
      <div :class="styles['router-div']">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import styles from './App.module.scss'
import { defineComponent } from 'vue'
// import CommonMain from '@/views/common-main.vue'

// import { inject } from 'vue'
export default defineComponent({
  name: 'App',
  props: {
    which: String,
  },
  components: {
    // CommonMain,
    // sidebar,
  },
  mounted() {
    const config = {
      app: 'common',
      route: 'common',
      sidebar: {
        show: false,
      },
      footer: {
        show: false,
      },
    }
    localStorage.setItem('config', JSON.stringify(config))
  },
  data() {
    return {
      styles
    }
  }
})
</script>
