// import { computed } from 'vue'
const isRoot = localStorage.getItem("global");

// eslint-disable-next-line import/prefer-default-export
export const workspaceRoutes = [
  {
    path:
      isRoot === "dronos"
        ? "/common/member-details/:id"
        : "/member-details/:id",
    name: "member-details",
    component: () =>
      import("@/views/workspace/member-details-page/member-details.vue"),
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: isRoot === "dronos" ? "/common" : "/overview",
    name: "overview",
    component: () => import("@/components/overview/overview.vue"),
  },
  {
    path: isRoot === "dronos" ? "/common/about" : "/about",
    name: "about",
    component: () =>
      import("@/views/workspace/about-workspace/about-workspace.vue"),
    meta: {
      layout: "full",
      auth: false,
    },
  },
  {
    path: isRoot === "dronos" ? "/common/my-account" : "/my-account",
    name: "Account Page",
    component: () => import("@/components/account-page/account-page.vue"),
    // meta: {
    //   layout: 'full',
    //   auth: false,
    // },
  },
];
