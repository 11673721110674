import { createApp, h } from "vue";
import singleSpaVue from "single-spa-vue";
import { QueryClient, VueQueryPlugin } from "@tanstack/vue-query";
import App from "./App.vue";

import router from "./router";

import "@/scss/core.scss";
import '@/scss/global.scss'; 
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";
import './config-hsf'
// eslint-disable-next-line import/order
// import globalState from '@/state/globalState'
// app.config.globalProperties.global = null

const myClient = new QueryClient();
const vueQueryPluginOptions = {
  queryClient: myClient,
};

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, { which: this.mainApp });
    },
  },
  handleInstance: (app1) => {
    app1.use(router);
    app1.use(VueQueryPlugin, vueQueryPluginOptions);
    // eslint-disable-next-line no-param-reassign
    // app1.config.globalProperties.global = 'lalala'
  },
});

export const { bootstrap } = vueLifecycles;
// eslint-disable-next-line prefer-destructuring
// export const mount = vueLifecycles.mount
export function mount(props) {
  let mainApp = "default";
  // Vue.prototype.$props = props
  if (props?.mainApp !== undefined) {
    mainApp = "dronos";
  }
  // Vue.prototype.$mainApp = mainApp
  localStorage.setItem("global", mainApp);
  // app.config.globalProperties.global = mainApp
  return vueLifecycles.mount(props);
}
// eslint-disable-next-line prefer-destructuring
export const unmount = vueLifecycles.unmount;
