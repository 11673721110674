// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap);"]);
// Module
exports.push([module.id, "html body{font-size:1rem;background-color:#fff}:root{font-size:12px!important;--sidebar-width:0px;--footer-height:0px}.App-module_common-container_2W13q{display:flex;overflow:hidden;background-color:#14171f;position:absolute;z-index:1;height:calc(100% - var(--footer-height, 0px));width:calc(100% - var(--sidebar-width, 0px));left:var(--sidebar-width,0)}.App-module_router-div_3Cwyl{margin-left:164px;width:calc(100% - 164px);margin-top:4rem}", ""]);
// Exports
exports.locals = {
	"common-container": "App-module_common-container_2W13q",
	"router-div": "App-module_router-div_3Cwyl"
};
module.exports = exports;
